export class CriptografiaUtil {
    static crypt64(valor: any): string {
        return btoa(JSON.stringify(valor));
    }

    static decrypt64<T>(valor?: string | null): T | null {
        if (!valor)
            return null;

        try {
            return <T>JSON.parse(atob(valor!));
        } catch {
            return null;
        }
    }
}